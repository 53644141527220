<script>
import Layout from '../layout/Layout'
import Menu from './menu'
import Mixin from './mixin'

export default {
  name: 'Pessoass',
  provide: function () {
    return {
      mainLayout: this
    }
  },
  mixins: [Mixin],
  components: {
    Layout
  },
  data () {
    return {
      activeMenu: 'pessoas'
    }
  },
  computed: {
    menu () {
      return Menu
    }
  },
  mounted () {
  },
  destroyed () {
  },
  methods: {
  },
  meta: {
    title: 'Pessoas',
    name: 'Pessoas'
  }
}
</script>

<template>
  <layout no-menu class="pessoa" no-title :menu="menu"
          :menu-active="activeMenu" pad hfull>
    <router-view />
  </layout>
</template>
